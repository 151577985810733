import { TwStyle } from 'twin.macro'
import { Button } from 'src/components/Button/Button'
import { Portal } from 'src/components/Portal/Portal'
import { Overlay } from 'src/components/Overlay/Overlay'

export interface Props {
  message?: string | React.ReactNode
  warnMessage?: string
  description?: string
  OverlayCss?: (string | TwStyle)[]
  buttonYesTitle?: string
  buttonNoTitle?: string
  buttonYesCss?: (string | TwStyle)[]
  onButtonYesClick: () => void
  onButtonNoClick: () => void
  onModalClosed?: () => boolean
}

export function ConfirmModal({
  message,
  warnMessage,
  description,
  OverlayCss,
  buttonYesTitle,
  buttonYesCss,
  onButtonYesClick,
  buttonNoTitle,
  onButtonNoClick,
  onModalClosed,
}: Props) {
  return (
    <Portal>
      <Overlay onClick={onModalClosed} css={OverlayCss}>
        <div tw="relative flex flex-col justify-between items-center w-11/12 max-w-lg bg-white rounded-lg p-24px max-h-5/6 overflow-y-auto">
          <div tw="text-center text-18px font-bold text-green-deep whitespace-pre-wrap">{message}</div>
          {warnMessage && (
            <p tw="text-center text-18px font-bold text-red whitespace-pre-wrap mt-16px">{warnMessage}</p>
          )}
          {description && (
            <p tw="text-center text-14px font-medium text-green-deep whitespace-pre-wrap mt-16px">{description}</p>
          )}
          <Button
            type="button"
            tw="bg-orange text-white w-4/5 flex-shrink-0 mt-16px"
            css={buttonYesCss}
            onClick={onButtonYesClick}
          >
            <span tw="text-14px">{buttonYesTitle}</span>
          </Button>
          <Button type="button" tw="w-4/5 flex-shrink-0 border border-green mt-1" onClick={onButtonNoClick}>
            <span tw="text-14px">{buttonNoTitle}</span>
          </Button>
        </div>
      </Overlay>
    </Portal>
  )
}
