import React, { createContext, useContext } from 'react'
import { Props as SuccessModalProps, SuccessModal } from 'src/components/SuccessModal/SuccessModal'
import { useImmerReducer } from 'use-immer'
import { AlertModal, Props as AlertModalProps } from 'src/components/AlertModal/AlertModal'
import { ConfirmModal, Props as ConfirmModalProps } from 'src/components/ConfirmModal/ConfirmModal'
import { LoginModal, Props as LoginModalProps } from 'src/components/LoginModal/LoginModal'
import { WarningModal, Props as WarningModalProps } from 'src/components/WarningModal/WarningModal'
import { Spinner } from 'src/components/Spinner/Spinner'
import {
  YcfStopStatusModal,
  Props as YcfStopStatusModalProps,
} from 'src/components/YcfStopStatusModal/YcfStopStatusModal'
import {
  YcfJudgingStatusModal,
  Props as YcfJudgingStatusModalProps,
} from 'src/components/YcfJudgingStatusModal/YcfJudgingStatusModal'
import {
  YcfUnusableStatusModal,
  Props as YcfUnusableStatusModalProps,
} from 'src/components/YcfUnusableStatusModal/YcfUnusableStatusModal'
import { ImageAlertModal, Props as ImageAlertModalProps } from 'src/components/ImageAlertModal/ImageAlertModal'
import { UnBuyableUserModal } from 'src/components/UnBuyableUserModal'

interface State {
  successModal: Partial<SuccessModalProps> & {
    visible: boolean
  }
  alertModal: Partial<AlertModalProps> & {
    visible: boolean
  }
  confirmModal: Partial<ConfirmModalProps> & {
    visible: boolean
  }
  loginModal: Partial<LoginModalProps> & {
    visible: boolean
  }
  warningModal: Partial<WarningModalProps> & {
    visible: boolean
  }
  isGlobalLoading: boolean

  ycfStopStatusModal: Partial<YcfStopStatusModalProps> & {
    visible: boolean
  }
  ycfJudgingStatusModal: Partial<YcfJudgingStatusModalProps> & {
    visible: boolean
  }
  ycfUnusableStatusModal: Partial<YcfUnusableStatusModalProps> & {
    visible: boolean
  }
  imageAlertModal: Partial<ImageAlertModalProps> & {
    visible: boolean
  }
  unBuyableUserModal: {
    visible: boolean
  }
}

type Action =
  | {
      type: 'showSuccessModal'
      payload: SuccessModalProps
    }
  | {
      type: 'hideSuccessModal'
    }
  | {
      type: 'showAlertModal'
      payload: AlertModalProps
    }
  | {
      type: 'hideAlertModal'
    }
  | {
      type: 'showConfirmModal'
      payload: Omit<ConfirmModalProps, 'onButtonNoClick'> & Partial<Pick<ConfirmModalProps, 'onButtonNoClick'>>
    }
  | {
      type: 'hideConfirmModal'
    }
  | {
      type: 'showLoginModal'
      payload: Omit<LoginModalProps, 'onClickOverlay'>
    }
  | {
      type: 'hideLoginModal'
    }
  | {
      type: 'showWarningModal'
      payload: WarningModalProps
    }
  | {
      type: 'hideWarningModal'
    }
  | {
      type: 'showGlobalLoading'
    }
  | {
      type: 'hideGlobalLoading'
    }
  | {
      type: 'showYcfStopStatusModal'
    }
  | {
      type: 'hideYcfStopStatusModal'
    }
  | {
      type: 'showYcfJudgingStatusModal'
    }
  | {
      type: 'hideYcfJudgingStatusModal'
    }
  | {
      type: 'showYcfUnusableStatusModal'
    }
  | {
      type: 'hideYcfUnusableStatusModal'
    }
  | {
      type: 'showImageAlertModal'
      payload: ImageAlertModalProps
    }
  | {
      type: 'hideImageAlertModal'
    }
  | {
      type: 'showUnBuyableUserModal'
    }
  | {
      type: 'hideUnBuyableUserModal'
    }

type Dispatch = (action: Action) => void

export const UiStateContext = createContext<State | undefined>(undefined)
export const UiDispatchContext = createContext<Dispatch | undefined>(undefined)

const initialState: State = {
  successModal: {
    visible: false,
  },
  alertModal: {
    visible: false,
  },
  confirmModal: {
    visible: false,
  },
  loginModal: {
    visible: false,
  },
  warningModal: {
    visible: false,
  },
  isGlobalLoading: false,
  ycfStopStatusModal: {
    visible: false,
  },
  ycfJudgingStatusModal: {
    visible: false,
  },
  ycfUnusableStatusModal: {
    visible: false,
  },
  imageAlertModal: {
    visible: false,
  },
  unBuyableUserModal: {
    visible: false,
  },
}

const immerReducer = (draft: State, action: Action) => {
  switch (action.type) {
    case 'showSuccessModal': {
      draft.successModal = {
        ...action.payload,
        visible: true,
      }
      break
    }
    case 'hideSuccessModal': {
      draft.successModal = initialState.successModal
      break
    }
    case 'showAlertModal': {
      draft.alertModal = {
        ...action.payload,
        visible: true,
      }
      break
    }
    case 'hideAlertModal': {
      draft.alertModal = initialState.alertModal
      break
    }
    case 'showConfirmModal': {
      draft.confirmModal = {
        ...action.payload,
        visible: true,
      }
      break
    }
    case 'hideConfirmModal': {
      draft.confirmModal = initialState.confirmModal
      break
    }
    case 'showLoginModal': {
      draft.loginModal = {
        ...action.payload,
        visible: true,
      }
      break
    }
    case 'hideLoginModal': {
      draft.loginModal = initialState.loginModal
      break
    }
    case 'showWarningModal': {
      draft.warningModal = {
        ...action.payload,
        visible: true,
      }
      break
    }
    case 'hideWarningModal': {
      draft.warningModal = initialState.warningModal
      break
    }
    case 'showGlobalLoading': {
      draft.isGlobalLoading = true
      break
    }
    case 'hideGlobalLoading': {
      draft.isGlobalLoading = false
      break
    }
    case 'showYcfStopStatusModal': {
      draft.ycfStopStatusModal = {
        visible: true,
      }
      break
    }
    case 'hideYcfStopStatusModal': {
      draft.ycfStopStatusModal = initialState.ycfStopStatusModal
      break
    }
    case 'showYcfJudgingStatusModal': {
      draft.ycfJudgingStatusModal = {
        visible: true,
      }
      break
    }
    case 'hideYcfJudgingStatusModal': {
      draft.ycfJudgingStatusModal = initialState.ycfJudgingStatusModal
      break
    }
    case 'showYcfUnusableStatusModal': {
      draft.ycfUnusableStatusModal = {
        visible: true,
      }
      break
    }
    case 'hideYcfUnusableStatusModal': {
      draft.ycfUnusableStatusModal = initialState.ycfUnusableStatusModal
      break
    }
    case 'showImageAlertModal': {
      draft.imageAlertModal = {
        ...action.payload,
        visible: true,
      }
      break
    }
    case 'hideImageAlertModal': {
      draft.imageAlertModal = initialState.imageAlertModal
      break
    }
    case 'showUnBuyableUserModal': {
      draft.unBuyableUserModal = {
        visible: true,
      }
      break
    }
    case 'hideUnBuyableUserModal': {
      draft.unBuyableUserModal = initialState.unBuyableUserModal
      break
    }
    default:
      console.log('ui reducer: unexpected action type')
      break
  }
}

export function UiProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useImmerReducer(immerReducer, initialState)
  const {
    successModal,
    alertModal,
    confirmModal,
    loginModal,
    warningModal,
    isGlobalLoading,
    ycfStopStatusModal,
    ycfJudgingStatusModal,
    ycfUnusableStatusModal,
    imageAlertModal,
    unBuyableUserModal,
  } = state
  return (
    <UiStateContext.Provider value={state}>
      <UiDispatchContext.Provider value={dispatch}>
        {children}
        {successModal.visible && (
          <SuccessModal
            message={successModal.message}
            buttonTitle={successModal.buttonTitle}
            onButtonClick={() => {
              successModal.onButtonClick && successModal.onButtonClick()
              dispatch({ type: 'hideSuccessModal' })
            }}
          />
        )}
        {alertModal.visible && (
          <AlertModal
            OverlayCss={alertModal.OverlayCss}
            message={alertModal.message}
            buttonTitle={alertModal.buttonTitle}
            onButtonClick={() => {
              alertModal.onButtonClick && alertModal.onButtonClick()
              dispatch({ type: 'hideAlertModal' })
            }}
          />
        )}
        {confirmModal.visible && (
          <ConfirmModal
            message={confirmModal.message}
            warnMessage={confirmModal.warnMessage}
            description={confirmModal.description}
            OverlayCss={confirmModal.OverlayCss}
            buttonYesTitle={confirmModal.buttonYesTitle}
            buttonNoTitle={confirmModal.buttonNoTitle}
            buttonYesCss={confirmModal.buttonYesCss}
            onButtonYesClick={() => {
              confirmModal.onButtonYesClick && confirmModal.onButtonYesClick()
              dispatch({ type: 'hideConfirmModal' })
            }}
            onButtonNoClick={() => {
              confirmModal.onButtonNoClick && confirmModal.onButtonNoClick()
              dispatch({ type: 'hideConfirmModal' })
            }}
            onModalClosed={() => {
              if (confirmModal.onModalClosed && confirmModal.onModalClosed()) {
                dispatch({ type: 'hideConfirmModal' })
              }
              return false
            }}
          />
        )}
        {loginModal.visible && (
          <LoginModal
            onBuyerButtonClicked={() => {
              loginModal.onBuyerButtonClicked && loginModal.onBuyerButtonClicked()
              dispatch({ type: 'hideLoginModal' })
            }}
            onSellerButtonClicked={() => {
              loginModal.onSellerButtonClicked && loginModal.onSellerButtonClicked()
              dispatch({ type: 'hideLoginModal' })
            }}
            onClickOverlay={() => {
              dispatch({ type: 'hideLoginModal' })
            }}
          />
        )}
        {warningModal.visible && (
          <WarningModal
            title={warningModal.title}
            message={warningModal.message}
            note={warningModal.note}
            buttonTitle={warningModal.buttonTitle}
            OverlayCss={warningModal.OverlayCss}
            messageCss={warningModal.messageCss}
            onButtonClick={() => {
              warningModal.onButtonClick && warningModal.onButtonClick()
              dispatch({ type: 'hideWarningModal' })
            }}
          />
        )}
        {isGlobalLoading && <Spinner />}
        {ycfStopStatusModal.visible && (
          <YcfStopStatusModal
            onModalClose={() => {
              dispatch({ type: 'hideYcfStopStatusModal' })
            }}
          />
        )}
        {ycfJudgingStatusModal.visible && (
          <YcfJudgingStatusModal
            onModalClose={() => {
              dispatch({ type: 'hideYcfJudgingStatusModal' })
            }}
          />
        )}
        {ycfUnusableStatusModal.visible && (
          <YcfUnusableStatusModal
            onModalClose={() => {
              dispatch({ type: 'hideYcfUnusableStatusModal' })
            }}
          />
        )}
        {imageAlertModal.visible && (
          <ImageAlertModal
            message={imageAlertModal.message}
            img={imageAlertModal.img}
            title={imageAlertModal.title}
            buttonTitle={imageAlertModal.buttonTitle}
            onButtonClick={() => {
              imageAlertModal.onButtonClick && imageAlertModal.onButtonClick()
              dispatch({ type: 'hideImageAlertModal' })
            }}
          />
        )}
        {unBuyableUserModal.visible && (
          <UnBuyableUserModal onClose={() => dispatch({ type: 'hideUnBuyableUserModal' })} />
        )}
      </UiDispatchContext.Provider>
    </UiStateContext.Provider>
  )
}

export const useUiDispatch = () => {
  const context = useContext(UiDispatchContext)
  if (context === undefined) {
    throw new Error('useUi must be used within a UiProvider')
  }

  return context
}
